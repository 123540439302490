<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row style="margin-bottom: 100px">
            <v-col cols="12">
                <v-card>
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo 
                                   class="ma-0 pa-2" hide-details=true clearable v-model="office" :items="offices" item-value="office_id" item-text="office"
                                   label="Office"
                                   @change="(event) => updateDepartment(event)"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="1">
                                <v-autocomplete clearable solo
                                    class="ma-0 pa-2"
                                    hide-details=true v-model="department" :items="dept_names" item-value="dept_id" item-text="dept_name"
                                    label="Department"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2" md="2">
                                <v-autocomplete clearable solo
                                    class="ma-0 pa-2"
                                    hide-details=true v-model="approve" :items="approves" item-value="value" item-text="descr"
                                    label="Approved"></v-autocomplete>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="2" md="2">
                                <v-autocomplete clearable solo
                                    class="ma-0 pa-2"
                                    hide-details=true v-model="statusModel" :items="statuses" item-value="value" item-text="status"
                                    label="Status"></v-autocomplete>
                            </v-col> -->
                             <v-col cols="12" xs="12" sm="2" md="1">
                                <v-autocomplete clearable solo
                                    class="ma-0 pa-2"
                                 hide-details=true v-model="statusModel" :items="statuses" default="" item-value="value" item-text="status" label="STATUS"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal" v-model="modal" :close-on-content-click="false"
                                    transition="scale-transition" offset-y max-width="290px"
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field solo v-model="date_from"
                                        label="Date From" persistent-hint
                                        prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" class="ma-0 pa-2"
                                        hide-details=true></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from" no-title
                                        @input="modal = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal_to" v-model="modal_to" :close-on-content-click="false"
                                    transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field solo v-model="date_to" label="Date to" persistent-hint
                                        prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" class="ma-0 pa-2"
                                        hide-details=true></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to" no-title
                                        @input="modal_to = false"></v-date-picker>
                                </v-menu>
                               </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-2" 
                                color="info" elevation="2" large @click="search()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" style="padding-bottom: 100px">
                <v-card v-show="display == 'none' ? false: true">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Result
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search PO ID"
                                        single-line
                                        @keyup.enter="search"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers"
                                    :items="purchases"
                                    :options.sync="options"
                                    :loading="loading"
                                    :item-class="itemRowBackground"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItems"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1"> 
                                      <template v-slot:[`item.status`]="{ item }">
                                    <v-chip class="ma-2" color="success" text-color="white" v-if="item.stat === 'O'" >
                                        OPEN
                                    </v-chip>
                                    <v-chip class="ma-2" color="red" text-color="black" v-if="item.stat === 'R'" >
                                        REJECT
                                    </v-chip>
                                    <v-chip class="ma-2" color="blue-grey" text-color="white" v-if="item.stat === 'C'">
                                        CLOSED
                                    </v-chip>
                                </template>
                                     <template v-slot:[`item.approve1`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                  <div v-if="$store.getters.UsernameUser === user_approve1">
                                                     <v-chip class="ma-2" color="info" v-if="item.dt_aprv == '1900-01-01 00:00:00.000' && item.dt_rjc == '1900-01-01 00:00:00.000'" @click="setApprove1(item)" v-on="on" >
                                                         <v-icon center> 
                                                            mdi-check-circle
                                                        </v-icon>
                                                            CONFIRM
                                                    </v-chip>
                                                     <v-chip class="ma-2" color="red" v-else-if="item.dt_rjc != '1900-01-01 00:00:00.000'" >
                                                        {{item.dt_rjc}}
                                                    </v-chip>
                                                 <v-chip class="ma-2" color="success" v-else>
                                                            {{item.dt_aprv}}
                                                    </v-chip>
                                                </div>
                                                <div v-else>
                                                    <v-chip class="ma-2" color="red" text-color="white" v-if="item.dt_aprv === '1900-01-01 00:00:00.000'" >
                                                            NOT APPROVED
                                                    </v-chip>
                                                    
                                                    <v-chip class="ma-2" color="success" v-else>
                                                            {{item.dt_aprv}}
                                                    </v-chip>
                                                </div>
                                            </template>
                                            <span>Confirm</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:[`item.approve2`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                  <div v-if="$store.getters.UsernameUser === user_approve2">
                                                     <v-chip class="ma-2" color="info" v-if="item.dt_aprv2 == '1900-01-01 00:00:00.000' && item.dt_rjc == '1900-01-01 00:00:00.000'" @click="setApprove2(item)" v-on="on" >
                                                         <v-icon center> 
                                                            mdi-check-circle
                                                        </v-icon>
                                                            CONFIRM
                                                    </v-chip>
                                                     <v-chip class="ma-2" color="red" v-else-if="item.dt_rjc != '1900-01-01 00:00:00.000'" >
                                                        {{item.dt_rjc}}
                                                    </v-chip>
                                                 <v-chip class="ma-2" color="success" v-else>
                                                            {{item.dt_aprv2}}
                                                    </v-chip>
                                                </div>
                                                <div v-else>
                                                    <v-chip class="ma-2" color="red" text-color="white" v-if="item.dt_aprv2 === '1900-01-01 00:00:00.000'" >
                                                            NOT APPROVED
                                                    </v-chip>
                                                    
                                                    <v-chip class="ma-2" color="info" v-else>
                                                            {{item.dt_aprv2}}
                                                    </v-chip>
                                                </div>
                                            </template>
                                            <span>Confirm</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog v-model="dialog" max-width="1000px">                
                    <v-card>
                        <v-card-title>Detail PO Item</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card-title>PO Item
                                            <v-spacer></v-spacer>
                                            <v-text-field
                                                v-model="search_detail"
                                                append-icon="mdi-magnify"
                                                label="Search PO ID"
                                                single-line
                                                @keyup.enter="search_temp"
                                                hide-details
                                            ></v-text-field>
                                        </v-card-title>
                                        <v-data-table
                                            :headers="header_po_items"
                                            :items="po_items"
                                            :options.sync="option_po_items"
                                            :loading="loading3"
                                            loading-text="Please wait, retrieving data"
                                            :server-items-length="totalItem_po_items"
                                            :page.sync="pagination_item"
                                            page-count="10"
                                            class="elevation-1"
                                        >
                                            <template v-slot:[`item.amt_net`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.amt_net) }}
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                v-model="dialogAgree"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you approve this PO?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn
                        class="pull-left"
                            color="green darken-1"
                            text
                            @click="dialogAgree = false"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             Reject
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            :loading="loading4"
                            :disabled="loading4"
                            color="green darken-1"
                            text-color="white"
                            @click="submitApprove1()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                             Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-dialog
                v-model="dialogAgree2"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you approve this PO?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogAgree2 = false"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             Reject
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            :loading="loading5"
                            :disabled="loading5"
                            color="green darken-1"
                            text-color="white"
                            @click="submitApprove2()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                             Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-dialog
                v-model="dialogDisagree"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you Rollback Approve 1 this PO?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogDisagree = false"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             Reject
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            :loading="loading5"
                            :disabled="loading5"
                            color="green darken-1"
                            text-color="white"
                            @click="submitUnApprove()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                             Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-dialog
                v-model="dialogDisagree2"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you Rollback Approve 2 this PO?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogDisagree2 = false"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             Reject
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            :loading="loading5"
                            :disabled="loading5"
                            color="green darken-1"
                            text-color="white"
                            @click="submitUnApprove2()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                             Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
      </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'MKM',
                disabled: false,
                href: '/admin/MKM',
                },
                {
                text: 'Approval',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Purchase Order',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            offices: [],
            office: '',
            dept_names:[],
            dept_name:'',
            officeidlist:'',
            departments: [],
            department: '',
            approves: [
                {
                    descr: 'All',
                    value: ''
                },
                {
                    descr: 'Not Approved 1',
                    value: 'approve1'
                },
                {
                    descr: 'Not Approved 2',
                    value: 'approve2'
                }
            ],
            approve: '',
            statusModel: '',
            statuses: [
                {
                    status: 'OPEN',
                    value: 'O'
                },
                {
                    status: 'CLOSE',
                    value: 'C'
                }
            ],
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            display: 'block',
            purchases: [],
            headers: [
                {
                    text: 'PO_ID',
                    align: 'start',
                    sortable: true,
                    value: 'po_id',
                },
                { text: 'DATE PO', value: 'dt_po' },
                { text: 'DEPARTMENT', value: 'dept_name' },
                { text: 'PR_ID', value: 'pr_id' },
                { text: 'MEMO', value: 'memo_txt' },
                { text: 'STATUS', value: 'status' },
                { text: 'APPROVE 1',  value: 'approve1', align: 'center', sortable: false },
                { text: 'APPROVE 2',  value: 'approve2', align: 'center', sortable: false },
                { text: 'ACTION',  value: 'actions', sortable: false },
            ],
            totalItems: 10,
            options: {},
            loading2: false,
            loading_reject: false,
            search_detail: '',
            header_po_items:[
                {
                    text: 'PO ID',
                    align: 'start',
                    sortable: true,
                    value: 'po_id',
                },
                { text: 'PO Item', value: 'po_item' },
                { text: 'Article', value: 'description_article' },
                { text: 'Unit Price', value: 'unit_price' },
                { text: 'Qty', value: 'qty' },
                { text: 'Weight', value: 'wgt' },
                { text: 'Amount Net', value: 'amt_net' }
            ],
            po_items: [],
            po_item: '',
            option_po_items: {},
            loading3: false,
            loading4: false,
            loading5: false,
            totalItem_po_items: 10,
            dialog: false,
            data_var: {
                entity_id : 'MKM',
                appl_id : 'WEBMKM'
            },
            user_approve1: '',
            user_approve2: '',
            dialogAgree: false,
            dialogAgree2: false,
            dialogDisagree2: false,
            dialogDisagree: false,
            pagination: 1,
            pagination_item: 1
        }
    },
    mounted(){
        this.getOffice()
        this.getEnvConf()
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'APRVPO1') {
                        this.user_approve1 = res.data.data[i]['var_value']
                        if (this.user_approve1 != null) {
                            this.approve = 'approve1'

                            this.getPullData(1, 10)

                        }
                    }
                    
                    if (res.data.data[i]['var_id'] === 'APRVPO2') {
                        this.user_approve2 = res.data.data[i]['var_value']
                        if (this.user_approve2 != null) {
                            this.approve = 'approve2'

                            this.getPullData(1, 10)

                        }
                    }

                    if (this.approve != '') {
                        this.getPullData(1, 10)
                    }

                }

            })
        },
         itemRowBackground: function (item) {
                if (item.stat === 'O') {
                    return 'white-text text-darken-4 tr_datatable2'
                } else if(item.stat === 'C'){
                    return 'white-text text-darken-4  tr_datatablee'
                } else{
                    return 'yellow-text text-darken-4'
                }
            },
        datetime_temp(val) {

            var dateStr

            dateStr = new Date(val).toISOString().substr(0, 10).replace(/-/g,"")

            return dateStr;

        },
        search(){
            this.getPullData(1, 10, true)
        },
        search_temp(){
            this.pagination_item = 1
            this.getPullDataDetail(1, 10, this.po_item)
        },
        close() {
            this.dialog = false
        },
        showItem(item){
           // console.log(item);
            this.po_item = item
            this.po_items = []
            this.dialog = true
            this.pagination_item = 1
            this.getPullDataDetail(1, 10, this.po_item)
        },
        async getPullDataDetail(page = 1, itemsPerPage = 10, item){
            this.loading3 = true
            await axios.get(`${process.env.VUE_APP_URL}/api/mkm/purchase_order/getPurchaseOrderResultDetail?po_id=${item.po_id}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                //console.log(res.data)
                this.loading3 = false
                this.po_items = res.data.data
                this.totalItem_po_items = res.data.total
                this.pagination_item = res.data.current_page
            })
        },
        async getPullData(page = 1, itemsPerPage = 10, search){
            this.loading = true

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''
            
           await axios.get(`${process.env.VUE_APP_URL}/api/mkm/purchase_order/getPurchaseOrderResult?search=${this.$store.state.text ? this.$store.state.text : ''}&txtOffice=${this.office ? this.office : ""}&txtDepartment=${this.department ? this.department : ""}&txtApproved=${this.approve ? this.approve : ""}&txtStatus=${this.statusModel ? this.statusModel : ""}&start_date=${start_date ? start_date : ""}&end_date=${end_date ? end_date : ""}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                //console.log(res.data)
                this.display = 'block'
                this.loading = false
                this.purchases = res.data.data
                this.totalItems = res.data.total
                this.pagination = res.data.current_page
            })
        },
        getOffice(){
            axios.get(`${process.env.VUE_APP_URL}/api/mkm/purchase_order`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
               // console.log(res.data.officeidlist)
                this.offices = res.data.officeidlist

            });
        },
        updateDepartment(value){
           // console.log(value);
            this.loading2 = true
            axios.get(`${process.env.VUE_APP_URL}/api/mkm/purchase_order?officeid=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                //console.log(res.data.departmentlist)
                this.dept_names = res.data.departmentlist
                this.loading2 = false
               
            });
        },
        setApprove1(item){
            this.dialogAgree = true
            this.po_item = item
        },
        setApprove2(item){
            this.dialogAgree2 = true
            this.po_item = item
        },
        setUnApprove(item){
            this.dialogDisagree = true
            this.po_item = item
        },
        setUnApprove2(item){
            this.dialogDisagree2 = true
            this.po_item = item
        },
        async submitReject(){
            this.loading_reject = true

            await axios.post(`${process.env.VUE_APP_URL}/api/mkm/purchase_order/rejectApprovalPOsatu`, {
                'po_id': this.po_item.po_id
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading_reject = false
                this.dialogAgree = false
                this.dialogAgree2 = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Reject",
                    visible: true
                };
            }).catch(err => {
                this.loading_reject = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async submitReject2(){
            this.loading_reject = true

            await axios.post(`${process.env.VUE_APP_URL}/api/mkm/purchase_order/rejectApprovalPODua`, {
                'po_id': this.po_item.po_id
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading_reject = false
                this.dialogAgree = false
                this.dialogAgree2 = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Reject",
                    visible: true
                };
            }).catch(err => {
                this.loading_reject = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async submitApprove1(){
            this.loading4 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/mkm/purchase_order/updateApprovalPurchaseOrder`, {
                'po_id': this.po_item.po_id,
                'approve': 1
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading4 = false
                this.dialogAgree = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve 1",
                    visible: true
                };
            }).catch(err => {
                this.loading4 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async submitApprove2(){
            this.loading5 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/mkm/purchase_order/updateApprovalPurchaseOrderDua`, {
                'po_id': this.po_item.po_id,
                'approve': 2
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading5 = false
                this.dialogAgree2 = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve 2",
                    visible: true
                };
            }).catch(err => {
                this.loading5 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        // async submitUnApprove(){
        //     this.loading5 = true

        //     await axios.post(`${process.env.VUE_APP_URL}/api/mki/purchase_order/updateUnApprovalPurchaseOrder`, {
        //         'po_id': this.po_item.po_id,
        //         'approve': 1
        //     }, { 
        //         headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        //     }).then(res => {
        //         this.loading5 = false
        //         this.dialogDisagree = false
        //         this.getPullData()

        //         this.snackbar = {
        //             color: "success",
        //             icon: "mdi-checkbox-marked-circle",
        //             mode: "multi-line",
        //             position: "top",
        //             timeout: 7500,
        //             title: "Success",
        //             text: "Successfully UnApprove 1",
        //             visible: true
        //         };
        //     }).catch(err => {
        //         this.loading5 = false
        //         this.snackbar = {
        //             color: "error",
        //             icon: "mdi-alert-circle",
        //             mode: "multi-line",
        //             position: "top",
        //             timeout: 7500,
        //             title: "Error",
        //             text: err.response.data.message,
        //             visible: true
        //         };
        //     })
        // },
        // async submitUnApprove2(){
        //     this.loading5 = true

        //     await axios.post(`${process.env.VUE_APP_URL}/api/mki/purchase_order/updateUnApprovalPurchaseOrderDua`, {
        //         'po_id': this.po_item.po_id,
        //         'approve': 2
        //     }, { 
        //         headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        //     }).then(res => {
        //         this.loading5 = false
        //         this.dialogDisagree2 = false
        //         this.getPullData()

        //         this.snackbar = {
        //             color: "success",
        //             icon: "mdi-checkbox-marked-circle",
        //             mode: "multi-line",
        //             position: "top",
        //             timeout: 7500,
        //             title: "Success",
        //             text: "Successfully UnApprove 2",
        //             visible: true
        //         };
        //     }).catch(err => {
        //         this.loading5 = false
        //         this.snackbar = {
        //             color: "error",
        //             icon: "mdi-alert-circle",
        //             mode: "multi-line",
        //             position: "top",
        //             timeout: 7500,
        //             title: "Error",
        //             text: err.response.data.message,
        //             visible: true
        //         };
        //     })
        // },
        
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.display === 'block') {
                    this.getPullData(page, itemsPerPage)
                }
            },
            deep: true,
        },
        option_po_items: {
            handler () {
                this.loading5 = true
                const { page, itemsPerPage } = this.option_po_items
                if (this.po_item) {
                    this.getPullDataDetail(page, itemsPerPage, this.po_item)
                }
            },
            deep: true,
        },
    }
}
</script>
<style>
   .tr_datatablee{
        background-color: #b35151 !important;
        
    }
    .tr_datatable2{
        background-color: #2bb7ee !important;
        color: rgb(255, 240, 240);
        
    }
</style>